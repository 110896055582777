import queryString from 'query-string';
import { camelizeKeys } from 'humps';
import TokenManager from '@setapp/auth-tokens-manager';
import Authenticator from '@setapp/customer-authenticator';
import RequestError from '@setapp/request-error/dist/request-error';

import UiElement from '../../../shared/ui-element/ui-element';
import config from '../../../shared/config/config';
import analytics, { events } from '../../../shared/analytics';

import Logger from '../../utils/logger/logger';
import request from '../../utils/request/request';
import { detect } from '../../utils/browser';

import SignupFormStepEmail from './signup-form-step-email';
import SignupFormStepPassword from './signup-form-step-password';
import SignupFormStepName from './signup-form-step-name';

import './signup-form.scss';

const REGISTRATION_FLOW_SINGLE_APP = 'appLanding';
const DEFAULT_TIER_TYPE = 'regular:mac:monthly';
const IOS_TIER_TYPE = 'regular:ios:monthly';
const EDU_TIER_TYPE = 'regular:edu:annual';

export const MEMBERSHIP_PRICE_INCREASE_TEST_DEFAULT_TIER_TYPE = 'regular:mac:monthly:pricing2024';

/*
* Redirect timeout for analytics
* */
const REDIRECT_TIMEOUT = 300;
const STEP_ANIMATION_TIMEOUT = 150;

class SignupFormManager extends UiElement {
  ui = {
    socialButtonsBlock: '.js-social-buttons',
  };

  uiCollections = {
    socialSignupButtons: '.js-social-signup-button',
    formSteps: '.js-signup-form-step',
  };

  events = {
    'click @uiCollections.socialSignupButtons': 'handleSocialButtonClick',
  };

  signupParams = {};

  signupFormStepNames = {
    email: 'email',
    password: 'password',
    name: 'name',
  };

  constructor() {
    super({ rootElement: '#signup-form-manager' });

    this.initAuthenticator();

    this.signupFormStepEmail = new SignupFormStepEmail({
      onSubmit: this.handleEmailFormSubmit,
      isEduEmailValidationNeeded: this.isEduEmailValidationNeeded.bind(this),
    });
    this.signupFormStepPassword = new SignupFormStepPassword({
      onSubmit: this.handlePasswordFormSubmit,
      onBackButtonClick: this.handleBackButtonStepPasswordClick,
      onRetypeButtonClick: this.handleRetypeButtonClick,
    });
    this.signupFormStepName = new SignupFormStepName({
      onSubmit: this.handleUsernameFormSubmit,
      onBackButtonClick: this.handleBackButtonStepNameClick,
      onRetypeButtonClick: this.handleRetypeButtonClick,
    });
  }

  onInit({
    signupParams = {}, signupHideSocials, campaign, eventLabel,
  } = {}) {
    super.onInit();

    const { signupMobileAsIos, membershipPriceIncrease } = this.rootElement.dataset;

    this.signupFormStepEmail.init();
    this.signupFormStepPassword.init();
    this.signupFormStepName.init();

    const registrationFlow = this.getRegistrationFlow();
    if (registrationFlow) {
      this.signupParams = {
        registration_flow: registrationFlow,
        ...this.signupParams,
      };
    }

    if (campaign) {
      this.signupParams = {
        ...this.signupParams,
        campaign,
      };
    }

    const { isiOS } = detect();

    const isMembershipPriceIncreaseEnabled = Boolean(+membershipPriceIncrease);
    const isIosOnlyFlow = isiOS && Boolean(+signupMobileAsIos);

    let defaultTierType;

    if (isIosOnlyFlow) {
      defaultTierType = IOS_TIER_TYPE;
    } else if (isMembershipPriceIncreaseEnabled) {
      defaultTierType = MEMBERSHIP_PRICE_INCREASE_TEST_DEFAULT_TIER_TYPE;
    } else {
      defaultTierType = DEFAULT_TIER_TYPE;
    }

    this.signupParams = {
      ...this.signupParams,
      ...signupParams,
      /**
       * always check for a tier_type and set a default value if none passed to prevent form from sending tier_type
       * taken from the previous opening
       */
      tier_type: signupParams.tier_type || defaultTierType,
    };

    if (signupHideSocials) {
      this.hideSocialButtons();
    }

    this.updateSocialSignupParams(this.signupParams);

    analytics.trackEvent({
      ...events.SIGNUP_FORM_OPEN,
      eventLabel: eventLabel || '',
    });
  }

  destroy() {
    super.destroy();

    this.showSocialButtons();
  }

  initAuthenticator(options = {}) {
    const cookiesDomain = config.get('cookiesDomain');
    const tokenManager = new TokenManager({
      cookiesDomain,
      secureCookies: window.location.protocol === 'https:',
      rememberPeriod: 365,
      accessTokenName: 'customer_access_token',
      refreshTokenName: 'customer_refresh_token',
    });

    this.authenticator = new Authenticator({
      apiRoot: config.get('customerAccountAPI.root'),
      cookiesDomain,
      tokenManager,
      ...options,
    });
  }

  updateSignupParams(newParams) {
    this.signupParams = {
      ...this.signupParams,
      ...newParams,
    };
  }

  showSignupFormStep = (stepName) => {
    this.currentStep = stepName;
    this.rootElement.classList.toggle('signup-form-step_faded');

    setTimeout(() => {
      this.uiCollections.formSteps.forEach((step) => {
        if (step.dataset.stepName === stepName) {
          step.classList.remove('d-none');
        } else {
          step.classList.add('d-none');
        }
      });
      this.rootElement.classList.toggle('signup-form-step_faded');
    }, STEP_ANIMATION_TIMEOUT);
  };

  updateEmailPlaceholders(email) {
    this.signupFormStepPassword.updateEmailPlaceholder(email);
    this.signupFormStepName.updateEmailPlaceholder(email);
  }

  handleSocialButtonClick(e) {
    e.preventDefault();
    const socialAnalyticEvent = e.target.dataset.analyticEventAction;

    analytics.trackEvent(events[socialAnalyticEvent]);
    setTimeout(() => {
      window.location.assign(e.target.href);
    }, REDIRECT_TIMEOUT);
  }

  handleBackButtonStepPasswordClick = () => {
    this.showSignupFormStep(this.signupFormStepNames.email);
  };

  handleBackButtonStepNameClick = () => {
    this.showSignupFormStep(this.signupFormStepNames.password);
  };

  handleRetypeButtonClick = () => {
    this.showSignupFormStep(this.signupFormStepNames.email);
  };

  handleSubmitOnboarding = () => {
    this.showSignupFormStep(this.signupFormStepNames.email);
  };

  handleEmailFormSubmit = (data) => {
    this.updateSignupParams({ email: data.email });
    this.updateEmailPlaceholders(data.email);
    this.showSignupFormStep(this.signupFormStepNames.password);
  };

  handlePasswordFormSubmit = (data) => {
    this.updateSignupParams({ password: data.password });
    this.showSignupFormStep(this.signupFormStepNames.name);
  };

  handleUsernameFormSubmit = (data) => {
    this.updateSignupParams({
      name: data.name,
      marketing_subscribed: data.marketing_subscribed,
      terms_accepted: data.terms_accepted,
      captcha: data.captcha,
    });
    this.submitSignup();
  };

  submitSignup() {
    const locale = this.rootElement.dataset.language;

    return this.authenticator.signUp(this.signupParams, { language: locale })
      .then(() => this.handleSignUpSuccess())
      .catch((e) => this.handleSignUpError(e));
  }

  handleSignUpSuccess = () => this.fetchUserProfile()
    .then((user) => {
      analytics.trackEvent({
        ...events.SIGN_UP_SUCCESS,
        userEmail: user.email,
        userId: user.id,
      });

      if (this.signupParams.marketing_subscribed) {
        analytics.trackEvent(events.SIGN_UP_EMAIL_OPT_IN);
      }

      setTimeout(() => {
        if (user.registration_flow === REGISTRATION_FLOW_SINGLE_APP) {
          window.location.assign(config.get('customerAccountPages.successfulRegistrationAppLanding'));
        } else {
          window.location.assign(config.get('customerAccountPages.successfulRegistration'));
        }
      }, REDIRECT_TIMEOUT);
    });

  handleSignUpError = (error) => {
    this.signupFormStepName.enableSubmitBtn();

    if (!(error instanceof RequestError)) {
      Logger.catchError('Signup error', error);
      this.signupFormStepName.showFormError(error.message || this.rootElement.dataset.defaultError);

      return;
    }

    error.getAll()
      .forEach((currentError) => {
        const errorMessage = currentError.message || this.rootElement.dataset.defaultError;

        if (currentError.field === 'email') {
          this.signupFormStepEmail.showEmailFieldError(errorMessage);
          this.showSignupFormStep('email');
        } else if (currentError.field === 'name') {
          this.signupFormStepName.showFieldError('name', errorMessage);
        } else if (currentError.field === 'captcha') {
          if (this.signupFormStepName.isCaptchaRequired) {
            this.signupFormStepName.resetCaptcha();
          } else {
            this.signupFormStepName.requireCaptcha();
          }
        } else {
          Logger.catchError('Signup error', currentError);
          this.signupFormStepName.showFormError(errorMessage);
        }
      });
  };

  fetchUserProfile() {
    return request.get(config.get('customerAccountAPI.account'));
  }

  hideSocialButtons() {
    this.ui.socialButtonsBlock.classList.add('d-none');
  }

  showSocialButtons() {
    this.ui.socialButtonsBlock.classList.remove('d-none');
  }

  updateSocialSignupParams(params) {
    this.uiCollections.socialSignupButtons.forEach((element) => {
      const { signupParams = null, ...restQuery } = queryString.parse(element.search);

      // eslint-disable-next-line no-param-reassign
      element.search = queryString.stringify({
        ...restQuery,
        signupParams: JSON.stringify({
          ...JSON.parse(signupParams),
          ...camelizeKeys(params),
        }),
      });
    });
  }

  getRegistrationFlow() {
    return JSON.parse(this.rootElement.dataset.registrationFlow || null);
  }

  isEduEmailValidationNeeded() {
    return Boolean(this.signupParams.tier_type === EDU_TIER_TYPE);
  }
}

export default SignupFormManager;
