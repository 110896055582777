export const NEW_ACCOUNT_URL = process.env.NEW_ACCOUNT_URL || '';

export const ROUTE_PATHS = {
  newCustomerAccountPages: {
    index: NEW_ACCOUNT_URL,
    login: `${NEW_ACCOUNT_URL}/signin`,
    signup: `${NEW_ACCOUNT_URL}/signin`,
    subscriptions: `${NEW_ACCOUNT_URL}/purchases`,
  },
  newWebsitePages: {
    joinMembership: '/join/membership',
  },
} as const;
